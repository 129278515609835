<template>
    <v-btn class="ma-1" :small="isMobile" @click="sendPaymentDetails()" :icon="btnStyle == 'icon'" :v-bind="$attrs"><v-icon class="mr-1" title="Send Payment Details on WhatsApp">mdi-bank-outline</v-icon>
        <label v-if="btnStyle == 'btn'">Send Payment Details</label>
    </v-btn>
</template>

<script>
export default {
    props: {
        order: {
            type: Object,
            default: () => ''
        },
        btnStyle: {
            type: String,
            enum: ['icon', 'btn'],
            default: () => 'icon'
        }
    },
    methods: {
        sendPaymentDetails() {
            const message = `Dear ${this.order.customer.displayName},

Thanks you for your order. Please find below the payment details.
Bill Number: ${this.order.orderNo}
Amount: ₹${ this.order.balance == undefined? this.order.total:this.order.balance }/-

You can make payment using following link:

${this.generateUrl(this.$store.getters.vendor.slug)}/paymentsPage?orderId=${this.order._id}.

After payment please share payment screenshot here.

Regads,
${this.$store.getters.vendor.businessName}`;
            // URL-encode the message
            const encodedMessage = encodeURIComponent(message);

            // Create the WhatsApp link
            const whatsappLink = `https://wa.me/91${this.order.customer.value}?text=${encodedMessage}`;

            // Open the link in a new tab
            window.open(whatsappLink, '_blank');
        }
    },
}
</script>

<style lang="scss" scoped></style>