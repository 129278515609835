import { render, staticRenderFns } from "./PaymentsPage.vue?vue&type=template&id=1cf3681c&scoped=true"
import script from "./PaymentsPage.vue?vue&type=script&lang=js"
export * from "./PaymentsPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cf3681c",
  null
  
)

export default component.exports