<template>
  <v-card elevation="10">
    <v-card-text>
      <v-row align="center">
        <v-col cols="12">
          <h2>{{ title }}</h2>
          <p class="pl-2">{{ subtitle }}</p>
          <GChart type="PieChart" :data="chartData" :options="chartOptions" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { GChart } from 'vue-google-charts';
import appConstants from '@/utils/appConstants';
import _ from 'lodash';
import moment from 'moment';

export default {
  components: {
    GChart,
  },
  data() {
    return {
      span: 'monthly',
      items: [
        { label: 'Current Week', value: 'weekly' },
        { label: 'Current Month', value: 'monthly' },
        { label: 'Current Quarter', value: 'quarterly' }
      ],
      reportData: [],
      chartData: [],
      title: 'Earnings Report Summary',
      subtitle: '',
      chartOptions: {
        colors: ['orange', '#FFDBBB'],
        height: 400,
        pieHole: 0.4
      }
    };
  },
  mounted() {
    this.initComponent();
  },
  computed: {
    noData() {
      return _.isEmpty(this.reportData.data)
    }
  },
  methods: {
    initComponent() {
      this.fetchReport();
    },
    async fetchReport() {
      // Initialize the chartData with labels for the pie chart
      this.chartData = [
        ['Label', 'Count', 'Sales']  // Header row
      ];

      // Fetch data from the API based on the selected span
      this.reportData = await this.getItem(appConstants.MY_EARNINGS + '?span=' + this.span);

      // Check if the theme is updated and set the chart color accordingly
      if (this.$store.getters.vendor.themeUpdated) {
        const themeColor = this.$store.getters.vendor.themeColor;
        this.chartOptions.colors = [themeColor, this.lightenColor(themeColor, 0.2)];
      }

      // Variables to hold the aggregate sales
      let summary = this.reportData.data.find(rec => rec._id == this.$store.getters.vendor._id)
      // Update chartData to include the aggregated totals
      if (!summary) {
        this.chartData.push(
          ['No Data', 100, 0]);
        this.chartOptions.colors = ['grey'];
      } else {
        this.chartData.push(['Paid', summary.orderPaidAmount, summary.orderPaid]);
        this.chartData.push(['Credit', summary.orderCreditAmount, summary.orderCredit]);
      }

      // Update the title dynamically based on the span
      this.title = `My Earnings`;
      const startYear = moment(this.reportData.startDate, 'DD/MM/YYYY').year();
      const endYear = moment(this.reportData.endDate, 'DD/MM/YYYY').year();
      this.subtitle = `FY ${startYear} - ${endYear}`;
    },
    lightenColor(color, percent) {
      const num = parseInt(color.replace("#", ""), 16),
        amt = Math.round(2.55 * percent * 100),
        R = (num >> 16) + amt,
        G = (num >> 8 & 0x00FF) + amt,
        B = (num & 0x0000FF) + amt;
      return "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (G < 255 ? G < 1 ? 0 : G : 255) * 0x100 + (B < 255 ? B < 1 ? 0 : B : 255)).toString(16).slice(1).toUpperCase();
    }

  }
};
</script>

<style lang="scss" scoped></style>
