<template>
    <span>
        <v-btn class="ma-1" :small="isMobile" @click="sendPaymentReminder()" :icon="btnStyle == 'icon'" :v-bind="$attrs"><v-icon class="mr-1" title="Send Payment Reminder on WhatsApp">mdi-invoice-text-clock-outline</v-icon>
            <label v-if="btnStyle == 'btn'">Send Payment Reminder</label>
        </v-btn>
    </span>
</template>

<script>
export default {
    props: {
        order: {
            type: Object,
            default: () => ''
        },
        btnStyle: {
            type: String,
            enum: ['icon', 'btn'],
            default: () => 'icon'
        }
    },
    methods: {
        sendPaymentReminder() {
            const message = `Dear ${this.order.customer.displayName},

This is a gental reminder for your pending payment.
Bill Number: ${this.order.orderNo}
Amount Due: ₹${ this.order.balance == undefined? this.order.total:this.order.balance }/-

You can make payment using following link:

${this.generateUrl(this.$store.getters.vendor.slug)}/paymentsPage?orderId=${this.order._id}.

After payment please share payment screenshot here.

Please ignore this message, If you’ve already made the payment

Regads,
${this.$store.getters.vendor.businessName}`;
            // URL-encode the message
            const encodedMessage = encodeURIComponent(message);

            // Create the WhatsApp link
            const whatsappLink = `https://wa.me/91${this.order.customer.value}?text=${encodedMessage}`;

            // Open the link in a new tab
            window.open(whatsappLink, '_blank');
        }
    },
}
</script>

<style lang="scss" scoped></style>