<template>
    <v-row wrap>
        <v-col cols="12" sm="8">
            <v-card flat>
                <v-card-text v-if="product">
                    <whatsapp-product-share :small="true" @click="buildProductMsg()"
                        :whatasppProductMsg="buildProductMsg()"></whatsapp-product-share>
                    <v-card-title :style="themeInverted" class="text-transform-capitalize ">{{ product.title
                        }}</v-card-title>
                    <div class="text-center">
                        <video-player v-if="path && mimetype.startsWith('video/')" :src="path" height="500"
                            width="1000"></video-player>
                        <image-viewer v-else-if="path && mimetype.startsWith('image/')" contain
                            :selectedImageIndex="selectedImageIndex"
                            :src="product.images.map(image => imagePath(image.path))" height="400px"></image-viewer>
                        <v-flex text-center v-else><img height="300px" :src="defaultProductImage" /></v-flex>
                    </div>
                    <br>
                    <h3 v-if="product.images?.length > 0">More Images</h3>
                    <v-layout wrap>
                        <v-flex xs6 sm4 md3 class="pa-2" v-for="(image, index) in product.images" :key="image?.filename"
                            width="200">
                            <video-player v-if="image?.mimetype == 'video/mp4'" :src="image?.path" height="150"
                                width="150"></video-player>
                            <v-img v-else :src="imagePath(image?.path)" @click="selectedImage(image, index)"
                                max-width="150px" :height="150"></v-img>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="4">
            <v-card flat>
                <v-card-text v-if="product">
                    <v-card-title :style="themeInverted">Details</v-card-title>
                    <v-simple-table>
                        <v-layout wrap>
                            <v-flex wrap>
                                <v-card-title class="text-transform-capitalize ">{{ product.title }}</v-card-title>
                                <v-card-subtitle class="text-transform-capitalize" v-html="product.desc"></v-card-subtitle>
                                <v-divider></v-divider>
                                <!-- Show variant options -->
                                <product-variant-selector @change="variantSelected($event)"
                                    :variantsConfigs="variantsConfigs" :variants="variants"
                                    v-if="variants.length"></product-variant-selector>
                                <!--Show Bundled items if Product is Bundle-->
                                <row-item v-if="product.type == 'BUNDLE'" label='Bundled Items'></row-item>
                                <div class=" mt-n6" v-if="product.bType == 'FIXED'">
                                    <ul v-for="(product) in product.swapOptions" :key="product._id">
                                        <li>{{ product.title }}</li>
                                    </ul>
                                </div>
                                <!--Show Swappable items if Product is Bundle-->
                                <div class="mt-n6" v-else-if="product.swapOptions?.length > 0">
                                    <ul>
                                        <li>{{ product.swapOptions[0].title }}</li>
                                    </ul>
                                </div>
                                <div class="mt-n6" v-if="product.products?.length > 0">
                                    <ul v-for="(product) in product.products" :key="product._id">
                                        <li>{{ product.title }}</li>
                                    </ul>
                                </div>
                                <row-item label="Price : " :value="`₹${product.price?.toString()}`"></row-item>
                                <row-item v-if="product.offerprice" label="Offer Price : "
                                    :value="`₹${product.offerprice.toString()}`"></row-item>
                                <row-item label="Brand : "
                                    :value="product.brand ? product.brand.displayName : ''"></row-item>
                            </v-flex>
                        </v-layout>
                        <v-flex text-center>
                            <v-layout class="pb-2" column
                                v-if="product.stock > 0 || $store.getters.vendor.negativeStock || (product.bType == packageModule.bType.ONE_TIME || product.bType == packageModule.bType.FUTURE_BUY || product.type == 'BUNDLE')">
                                <add-to-cart v-model="product" v-if="product._id"></add-to-cart>
                                <order-button v-model="product" v-if="product._id"></order-button>
                            </v-layout>
                            <v-layout class="pb-2" column v-else>
                                <v-flex text-center class="stockOut">
                                    Out Of Stock
                                </v-flex>
                            </v-layout>
                            <whatsapp-product-share :block="true" @click="buildProductMsg()"
                                :whatasppProductMsg="buildProductMsg()"></whatsapp-product-share>
                            <v-btn block class="my-2" :style="theme" text @click="$router.go(-1)">
                                Go Back
                            </v-btn>
                        </v-flex>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
        <v-container>
            <whatsapp-chat></whatsapp-chat>
        </v-container>
    </v-row>
</template>

<script>
import axios from 'axios';
import OrderButton from '@/components/OrderButton.vue'
import appConstants from '@/utils/appConstants'
import addToCart from '@/components/AddToCart.vue';
import WhatsappChat from '../../components/WhatsappChat.vue'
import WhatsappProductShare from '@/components/WhatsappProductShare.vue'
import RowItem from '../../components/RowItem.vue';
import _ from 'lodash';
import ProductVariantSelector from './ProductVariantSelector.vue';
import ImageViewer from '../../components/ImageViewer.vue';
export default {
    components: {
        addToCart,
        OrderButton,
        WhatsappChat,
        WhatsappProductShare,
        RowItem,
        ProductVariantSelector,
        ImageViewer
    },
    data() {
        return {
            id: 0,
            product: {
                id: "",
                title: "",
                desc: "",
                price: 0,
                purchasePrice: 0,
                offerprice: 0,
                brand: {
                    displayName: ''
                },
                images: [],
                swapOptions: [],
                products: []
            },
            items: [],
            path: '',
            mimetype: '',
            variants: [],
            variantsConfigs: [],
            selectedImageIndex: 0
        }
    },
    created() {
        if (performance.navigation.type == 1)
            this.updateVendorSetting(this.$route.params.slug)
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.vendorId = JSON.parse(localStorage.getItem("vendor"))._id
            var productList = []
            this.product = this.checkInCart(this.id)
            if (!this.product) {
                await this.getItem(appConstants.WEBSTORE_PRODUCTS + "/" + this.id, {
                    headers: {
                        'vendorid': this.vendorId
                    }
                }).then(response => {
                    productList = response
                })
                // Check if provided id is not product then is it a Package
                if (productList.length == 0) {
                    productList = await this.getItem(appConstants.WEBSTORE_PACKAGES + "/" + this.id, { headers: { 'vendorid': this.vendorId } })
                    productList.forEach(rec => {
                        this.product = this.convertToProductRef(rec)
                    })
                }
                else {
                    productList.forEach(element => {
                        if (element._id == this.id)
                            this.product = element
                    });
                }
            }
            if (this.product._id && this.product.images?.length > 0) {
                this.path = this.product.images[0]?.path
                this.mimetype = this.product.images[0]?.mimetype
            }
            //Load product variants if product is either a variant or it's a parent product.
            if (this.product.hasVariant || this.product.isVariant) {
                this.loadVariants(this.product.isVariant ? this.product.parent : this.product._id, this.product.hasVariant)
            }
        },
        selectedImage(image, index = 0) {
            this.path = image?.path
            this.mimetype = image?.mimetype
            this.selectedImageIndex = index
        },
        buildProductMsg() {
            var price = this.product.offerprice ? this.product.offerprice : this.product.price
            var brand = this.product.brand ? this.product.brand.displayName : ''
            var desc = this.product.desc ? this.product.desc : ''
            return "Hi there,\nWelcome to *" + this.$store.getters.vendor.businessName + "*.\nCheckout our product.\n\n" +
                this.product.title + "\navailable at Price ₹" + price + "\n" + brand + "\n" + desc +
                "\nAccess product details at \n" + window.location.href
        },
        variantSelected(selectedVariant) {
            let newVariant = this.variants.find(variant => _.isEqual(variant.variant, selectedVariant))
            if (newVariant) {
                this.product = { ...newVariant }
                this.selectedImage(this.product?.images[0])
            }
        },
        async loadVariants(id, isParent = false) {
            this.variantsConfigs = (await this.getItem(`${appConstants.WEBSTORE_PRODUCTS}/${id}`))[0].variantsConfigs
            this.variants = (await this.getItem(`${appConstants.WEBSTORE_PRODUCTS}?conditions=parent=${id}`)).data
            if (isParent) {
                this.product = this.variants.find(v => v.isDefaultVariant)
            }
        },
    }
}
</script>

<style scoped></style>
