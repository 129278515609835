<template>
    <div v-if="variantsConfigs">
        <div v-for="variantConfig in variantsConfigs" :key="variantConfig?.field">
            <label v-if="variantConfig">{{ variantConfig?.label }}:
                <strong class="text-h6"> {{ selectedOption[variantConfig?.field] }}</strong>
            </label>
            <v-chip-group>
                <v-chip v-for="(value, index) in renderStructure[variantConfig?.field]" :key="index"
                    :disabled="!value?.available" outlined label
                    :style="value?.value == selectedOption[variantConfig?.field] ? themeInverted : ''"
                    @click="optionSelected(variantConfig?.field, value?.value)">
                    {{ value?.value }}
                </v-chip>
            </v-chip-group>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    props: {
        variantsConfigs: {
            type: Array,
            default: () => []
        },
        variants: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            selectedOption: undefined,
            currentSelected: {},
        };
    },
    mounted() {
        this.selectedOption = { ...this.variants.find(rec => rec.isDefaultVariant)?.variant };
        this.init()
    },
    methods: {
        init() {
            this.renderStructure = this.processVariants()
        },
        optionSelected(variant, option) {
            this.selectedOption[variant] = option;
            this.init()
            this.$emit('change', this.selectedOption)
        },
        processVariants() {
            const result = {};
            this.variantsConfigs.forEach(config => {
                result[config.field] = config.values.map(value => {
                    const isAvailable = this.variants.some(variant => {
                        return variant.variant[config?.field] === value &&
                            Object.entries(this.selectedOption).every(([key, selectedValue]) => {
                                return key === config.field || selectedValue === undefined || selectedValue === variant?.variant[key];
                            });
                    });
                    return {
                        value: value,
                        available: isAvailable
                    };
                });
            });
            return result;
        }
    }
};
</script>

<style lang="scss" scoped></style>
