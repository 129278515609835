<template>
    <v-menu v-if="isLoggedIn" offset-y>
        <template v-slot:activator="{ on }">
            <v-icon medium v-if="isLoggedIn" :style="theme" v-on="on">mdi-account-circle</v-icon>
        </template>
        <v-list>
            <v-list-item v-if="$vuetify.breakpoint.xsOnly">
                <label class="title-font">Welcome {{ getUserProfile().displayName }}</label>
            </v-list-item>
            <v-list-item @click="$router.push(`/app/editAccount/${getUserProfile()._id}`)">
                <v-list-item-title>
                    <v-icon medium class="secondary-color-inverted">mdi-account</v-icon>
                    <label class="title-font">Profile </label>
                </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
                <v-list-item-title>
                    <v-icon medium class="secondary-color-inverted">mdi-logout</v-icon>
                    <label class="title-font">Logout </label>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped></style>