<template>
    <v-card elevation="12">
        <v-card-text>
            <v-row align="center">
                <v-col cols="12" sm="8">
                    <h2>{{ title }}</h2>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-select @change="fetchReport()" v-model="span" :items="items" title="label" itemText="label"
                        value="value" label="Sales report for"></v-select>
                </v-col>
                <v-col cols="12">
                    <GChart class="px-2" type="ColumnChart" :data="chartData" :options="chartOptions" />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { GChart } from 'vue-google-charts'
import appConstants from '@/utils/appConstants'
export default {
    components: {
        GChart,
    },
    data() {
        return {
            span: 'monthly',
            items: [
                { label: 'Weekly', value: 'weekly' },
                { label: 'Monthly', value: 'monthly' },
                { label: 'Quarterly', value: 'quarterly' }
            ],
            reportData: [],
            chartData: {},
            title: '',
            chartOptions: { height: 400, colors: ['orange'] }
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        initComponent() {
            this.fetchReport()
        },
        async fetchReport() {
            this.chartData = [
                ['Label', 'Sales']
            ]
            this.reportData = await this.getItem(appConstants.SALES_REPORT + '?span=' + this.span)
            if (this.$store.getters.vendor.themeUpdated)
                this.chartOptions.colors = [this.$store.getters.vendor.themeColor]
            this.reportData.data.forEach(rec => {
                this.chartData.push([rec.label, rec.value])
            });
            this.title = this.span.charAt(0).toUpperCase() + this.span.slice(1) + ' Sales Report Summary'
        }
    },
}
</script>

<style lang="scss" scoped></style>
