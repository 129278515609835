<template>
    <div class="text-center black--text" style="min-height: 300px">
        <label v-if="payeeName"> Paying to: <label class="font-weight-bold">{{ payeeName }}</label></label>
        <label v-else> Paying to: <label class="font-weight-bold">{{ upiId }}</label></label>
        <qrcode id="qrcode" ref="qrcode" class="pa-4" :value="url" size="200" level="H"></qrcode>
        <label>Amount: <label class="font-weight-bold"><v-icon small>mdi-currency-inr</v-icon>{{ amount }}/-</label></label><br>
        <p>Note: {{ note }}</p>
        <p>OR</p>
        <v-btn :href="url"> <v-img src="@/assets/upi-logo.png" height="64px" width="64px"></v-img> Pay Now</v-btn>
    </div>
</template>

<script>
export default {
    props: {
        upiId: {
            type: String,
            required: true
        },
        payeeName: {
            type: String,
            required: false
        },
        amount: {
            type: Number,
            required: true
        },
        note: {
            type: String,
            required: false
        }
    },
    computed: {
        url() {
            /* let url = `upi://pay?pa=${this.upiId}&am=${this.amount}`
            if(this.payeeName)
                url += `&pn=${this.payeeName}`
            if(this.note)
                url += `&tn=${this.note}`
            return encodeURI(url) */
            return this.buildUpiPaymentLink(this.upiId, this.payeeName, this.amount, undefined, undefined, this.note)
        }
    },
}
</script>

<style lang="scss" scoped></style>