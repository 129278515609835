<template>
  <div style="min-height: 400px" class="text-center">
    <alert-message :msg="msg" :type="msgType" />
    <v-progress-circular v-if="loading" indeterminate color="orange"></v-progress-circular>
    <div v-else style="width: 50%" class="mx-auto my-auto py-15">
      <UpiQrCode v-if="!loading && upiId" :upiId="upiId" :payeeName="payeeName" :amount="amount" :note="note" />
      <v-alert v-else color="red" text type="error">Unable to load UPI details</v-alert>
    </div>
  </div>
</template>

<script>
import UpiQrCode from '@/components/UpiQrCode.vue'
import appConstants from '../../utils/appConstants'
import AlertMessage from '../../components/AlertMessage.vue'
export default {
  components: {
    UpiQrCode,
    AlertMessage
  },
  data() {
    return {
      upiId: '',
      payeeName: '',
      amount: 0,
      note: '',
      order: null,
      loading: false
    }
  },
  async mounted() {
    this.loading = true
    setTimeout(async () => {
      await this.init();
      this.loading = false
    }, 2000)
  },
  methods: {
    async init() {
      try {

        this.loading = true
        this.order = await this.getItem(`${appConstants.ORDER_DETAILS_FOR_UPI}/${this.$route.query.orderId}`)
        this.upiId = this.$store.getters?.vendor?.paymentDetails?.upiId
        this.payeeName = this.$store.getters?.vendor?.businessName || this.upiId
        this.amount = this.order.balance == undefined ? this.order.total : this.order.balance
        this.note = `Payment for Order ${this.order.orderNo}`
      } catch (error) {
        this.handleError(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>