<template>
    <v-card class="d-flex flex-column" v-if="product" height="100%" flat elevation="5">
        <div @click="showProductDetail(product._id)" class="py-4 link">
            <video-player v-if="isContentPresent && product.images[0]?.mimetype.startsWith('video/')"
                :src="product.images[0]?.path" height="200"></video-player>
            <v-img v-if="isContentPresent && product.images[0]?.mimetype.startsWith('image/')"
                class="white--text align-end" height="200" width="100%"
                :src="imagePath(product.images[0]?.path)"></v-img>
            <v-img v-if="!isContentPresent" class="white--text align-end" height="200"
                :src="defaultProductImage"></v-img>
        </div>
        <label v-if="product.brand" class="subtitle">{{ product.brand.displayName }}</label>
        <label class="title-font link font-weight-regular" @click="showProductDetail(product._id)">{{ product.title
            }}</label>
        <label class="ml-4 text-caption grey--text">{{ product.code }}</label>
        <!-- <v-card-text class="text--primary"> -->
        <div class="ml-4 text-body-2">
            <span
                :class="product.offerprice == null || product.offerprice == 0 ? 'price-amount font-weight-bold' : 'strike-through text-caption'">₹{{
                    product.price }}/-</span>
            <span v-if="product.offerprice > 0" class="price-amount font-weight-bold">₹{{ product.offerprice
                }}/-</span>

        </div>
        <!-- </v-card-text> -->
        <v-spacer></v-spacer>
        <v-card-actions>
            <v-row class="pb-2" column
                v-if="product.stock > 0 || $store.getters.vendor.negativeStock || (product.bType == 'ONE_TIME' || product.bType == 'FUTURE_BUY' || product.type == 'BUNDLE')">
                <v-col>
                    <add-to-cart v-model="product"></add-to-cart>
                </v-col>
                <!-- <order-button v-model="product"></order-button> -->
            </v-row>
            <v-row class="pb-2" column v-else>
                <v-col text-center class="stockOut text-center">
                    Out Of Stock
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import OrderButton from '@/components/OrderButton.vue'
import addToCart from '@/components/AddToCart.vue';
export default {
    components: {
        addToCart,
        OrderButton
    },
    props: {
        item: Object
    },
    data() {
        return {
            selectedVariant: {}
        }
    },
    computed: {
        product() {
            return this.item;
        },
        isContentPresent() {
            return this.product.images && this.product.images.length > 0 && this.product.images[0] != null
        }
    },
    methods: {
        showProductDetail(id) {
            this.$router.push("/store/products/" + id);
        }
    }
};
</script>

<style scoped>
.title-font {
    text-transform: capitalize;
    font-size: 1rem !important;
    font-weight: 500 !important;
    padding: 2px 16px !important;
    display: flex !important;
    word-wrap: normal !important;
    line-height: 1.25rem !important;
}

.subtitle {
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 0 16px !important;
    padding-top: 10px !important;
    padding-bottom: 2px;
    display: flex !important;
    word-wrap: break-all !important;
    line-height: 1.25rem !important;
    color: gray;
}

.link:hover {
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .subtitle {
        font-size: 10px !important;
        font-weight: 400 !important;
        padding: 0 16px !important;
        padding-top: 10px !important;
        padding-bottom: 2px;
        display: flex !important;
        word-wrap: break-all !important;
        line-height: 1.25rem !important;
        color: gray;
    }
}

.strike-through {
    text-decoration: line-through;
}

.price-amount {
    font-size: 1rem;
}
</style>
