<template>
    <div class="home">
        <v-alert v-if="msg" :type="msgType">{{ msg }}</v-alert>
        <!-- <push-notification v-if="!$store.getters.notification||$store.getters.vendor.slug!=$store.getters.notification.slug||($store.getters.user&&$store.getters.user._id!=$store.getters.notification.userId)"></push-notification> -->
        <div v-if="$store.getters.vendor.showBanner">
            <v-carousel :height="$vuetify.breakpoint.mdAndUp ? 500 : 'auto'" cycle hide-delimiter-background
                show-arrows-on-hover
                v-if="$store.getters.vendor.bannerImages && $store.getters.vendor.bannerImages?.length > 0">
                <v-carousel-item v-for="(image, i) in $store.getters.vendor.bannerImages" :key="i">
                    <v-img :contain="$vuetify.breakpoint.mdAndUp" :height="$vuetify.breakpoint.mdAndUp ? 500 : 'auto'"
                        :src="host + image.path"></v-img>
                </v-carousel-item>
            </v-carousel>
            <v-carousel :height="$vuetify.breakpoint.mdAndUp ? 500 : '250'" cycle hide-delimiter-background
                show-arrows-on-hover v-else>
                <v-carousel-item v-for="(slide, i) in slides" :key="i">
                    <v-sheet :color="colors[i]" height="100%">
                        <v-row class="fill-height" align="center" justify="center">
                            <div class="display-3">{{ slide }} Slide</div>
                        </v-row>
                    </v-sheet>
                </v-carousel-item>
            </v-carousel>
        </div>
        <v-container>
            <div text-center>
                <progress-bar :show="fetchingData" size="70"></progress-bar>
            </div>
            <div v-if="!fetchingData && items?.length == 0" class="noContent" text-center>
                <h1>No Products found</h1>
            </div>
            <whatsapp-chat></whatsapp-chat>
            <h2 v-if="coupons?.length > 0" :style="themeInverted">Offers</h2>
            <slider :couponArray="coupons"></slider>
            <div class="text-right">
                <whatsapp-share :whatasppMsg="buildProductCatalogMsg()"></whatsapp-share>
            </div>
            <div>
                <h2 v-if="category?.length > 0" :style="themeInverted">Categories</h2>
                <v-row justify="center">
                    <v-col v-for="item in category" :key="item._id" cols="6" sm="4" md="3" lg="2">
                        <category-card :item="item"></category-card>
                    </v-col>
                </v-row>
            </div>
            <h2 v-if="items.data?.length > 0" :style="themeInverted">Products</h2>
            <v-row v-if="currentProducts && currentProducts?.length > 0 || searchText">
                <v-col cols="12">
                    <v-text-field placeholder="Search Products by name" v-model="searchText" class="no-space" solo
                        @click:clear="searchProduct(false)" clearable>
                        <template v-slot:append>
                            <v-btn :style="theme" @click="searchProduct(true)"><v-icon>mdi-magnify</v-icon></v-btn>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row wrap v-if="items">
                <v-col v-if="!fetchingData && currentProducts?.length == 0" class="noContent" text-center>
                    <h1>No Product found</h1>
                </v-col>
                <v-col :cols="$store.getters.vendor.imageInRow > 1 ? 6 : 12" sm="4" md="3" lg="2"
                    v-for="(item, index) in currentProducts" :key="index">
                    <product-card :item="item"></product-card>
                </v-col>
            </v-row>
            <intersection-observer sentinal-name="home" @onIntersection="fetchData"></intersection-observer>
            <v-flex text-center>
                <progress-bar :show="loading" size="30"></progress-bar>
            </v-flex>
        </v-container>
        {{ latestData }}
        <v-alert v-if="msg" :type="msgType">{{ msg }}</v-alert>
        <cart-bar></cart-bar>
    </div>
</template>

<script>
import IntersectionObserver from '@/components/IntersectionObserver'
import appConstants from '@/utils/appConstants'
import ProductCard from '@/components/ProductCard.vue'
import VendorCard from '@/components/VendorCard.vue'
import CartBar from '@/components/CartBar.vue';
import WhatsappChat from '@/components/WhatsappChat.vue'
import Slider from '@/components/Slider.vue'
import PushNotification from '@/components/PushNotification.vue'
export default {
    name: 'Home',
    components: {
        ProductCard,
        VendorCard,
        CartBar,
        IntersectionObserver,
        WhatsappChat,
        Slider,
        PushNotification
    },
    data() {
        return {
            loading: false,
            colors: [
                'indigo',
                'pink darken-2',
                'red lighten-1',
                'deep-purple accent-4',
            ],
            slides: [
                'First',
                'Second',
                'Third',
                'Fourth',
            ],
            items: [],
            meta: {},
            category: [],
            vendor: [],
            slug: '',
            fetchingData: false,
            mobileView: '',
            searchText: '',
            currentProducts: [],
            pageInfo: { link: {}, meta: {} },
            refresh: false,
            search: false,
            coupons: []
        }
    },
    created() {
        if (performance.navigation.type == 1)
            this.updateVendorSetting(this.$route.params.slug)
    },
    mounted() {
        //this logic will activated if user switch between stores
        this.$store.subscribeAction((action, state) => {
            this.refresh = action.type == 'actionUpdateVendor'
        })
        if (!this.refresh)
            this.initComponent();
    },
    computed: {
        latestData() {
            if (this.refresh)
                this.$router.go(this.$router.currentRoute)
        }
    },
    methods: {
        async initComponent() {
            if (this.$store.getters.vendor.imageInRow > 1)
                this.mobileView = "xs6"
            else
                this.mobileView = "xs12"
            try {
                this.slug = this.$route.params.slug
                if (this.slug) {
                    await this.getDetails()
                }
            } catch (error) {
                this.handleError(error)
            }
        },
        async getDetails() {
            this.fetchingData = true
            try {
                this.coupons = await this.getItem(appConstants.WEBSTORE_COUPONS)
                this.category = await this.getItem(appConstants.WEBSTORE_CATEGORIES + "/parentCategories")
                await this.getProducts(1)
            } catch (error) {
                this.handleError(error)
            }
            this.fetchingData = false
        },
        showCategory() {
            this.show = !this.show
            this.getDetails()
        },
        async getProducts(pageNo) {
            let packages
            let productListStyle = this.$store.getters.vendor.webstoreProductsList ? this.$store.getters.vendor.webstoreProductsList : 'defaultVariants'
            
            // Clear current products if it's the first page or a search
            if (pageNo === 1) {
                this.currentProducts = [];
            }
            
            if (this.search)
                this.items = await this.getItem(`${appConstants.WEBSTORE_PRODUCTS}?variant=${productListStyle}&pageNo=${pageNo}&searchText=${this.searchText}`)
            else {
                this.items = await this.getItem(`${appConstants.WEBSTORE_PRODUCTS}?variant=${productListStyle}&pageNo=${pageNo}`)
                packages = (await this.getItem(appConstants.WEBSTORE_PACKAGES + '?pageNo=' + pageNo)).data
                for (let i = 0; i < packages?.length; i++) {
                    packages[i] = this.convertToProductRef(packages[i])
                }
            }
            
            // Add new products, avoiding duplicates by checking IDs
            if (this.items.data && this.items.data.length > 0) {
                const existingIds = new Set(this.currentProducts.map(item => item._id));
                const newItems = this.items.data.filter(item => !existingIds.has(item._id));
                this.currentProducts = this.currentProducts.concat(newItems);
            }
            
            // Add packages, avoiding duplicates
            if (packages?.length > 0) {
                const existingIds = new Set(this.currentProducts.map(item => item._id));
                const newPackages = packages.filter(pkg => !existingIds.has(pkg._id));
                this.currentProducts = this.currentProducts.concat(newPackages);
            }
            
            this.pageInfo['link'] = this.items.links
            this.pageInfo['meta'] = this.items.meta
        },
        async fetchData(value) {
            this.loading = true
            if (value && this.pageInfo && this.pageInfo.link.next != undefined && this.pageInfo.link.last != 0) {
                await this.getProducts(this.pageInfo.link.next)
            }
            this.loading = false
        },
        searchProduct(search) {
            if (!search)
                this.searchText = ''
            this.search = search
            this.currentProducts = []
            this.getProducts(1)
        }
    },
}
</script>

<style scoped>
.col-6 {
    padding: 6px;
}

.noContent {
    margin-top: 5rem;
}

h2 {
    display: block;
}
</style>
