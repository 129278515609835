<template>
    <v-app :style="themeInverted">
        <v-main>
            <enable-notifications></enable-notifications>
            <router-view />
            <router-view name="login" />
            <notifications position="bottom right" />
        </v-main>
    </v-app>
</template>

<script>
import EnableNotifications from './components/EnableNotifications.vue';
export default {
    components: { EnableNotifications },
    name: "App",
    mounted() {
        let title = this.$store?.getters?.vendor?.businessName || 'Online Dukan';
        document.title = title
        if(process.env.VUE_APP_GTAG){
            this.$gtag.event('page_view', {
                page_title: title,
                page_path: `${this.$store?.getters?.vendor?.slug}/store`,
                page_location: `https://app.onlindukanhub.in${this.$store?.getters?.vendor?.slug}/store`
            });
            this.$gtag.event('vendor_store_visit', {
                vendor_id: this.$store?.getters?.vendor?._id,
                vendor_store: `${this.$store?.getters?.vendor?.slug}/store`,
                vendor_name: title
            });
        }
    }
};
</script>
