<template>
    <div class="image-container">
        <img :src="src[selectedImageIndex]" alt="Image" class="main-image" height="500px" @click="openDialog()" />
        <app-dialog :show="showZoom" @close="closeDialog">
            <template v-if="src.length > 1">
                <v-carousel height="auto">
                    <v-carousel-item v-for="(image, index) in src" :key="index">
                        <v-img :src="image" height="auto"></v-img>
                    </v-carousel-item>
                </v-carousel>
            </template>
            <template v-else>
                <v-img :src="src[0]"></v-img>
            </template>
        </app-dialog>
    </div>
</template>

<script>
import AppDialog from './AppDialog.vue';
import { VCarousel, VCarouselItem, VImg } from 'vuetify/lib';

export default {
    components: { AppDialog, VCarousel, VCarouselItem, VImg },
    name: 'ImageWithZoom',
    props: {
        src: {
            type: Array,
            required: true
        },
        selectedImageIndex: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            showZoom: false
        };
    },
    methods: {
        openDialog() {
            this.showZoom = true;
        },
        closeDialog() {
            this.showZoom = false;
        }
    }
};
</script>

<style scoped>
.image-container {
    position: relative;
    display: inline-block;
}

.main-image {
    width: 100%;
    height: 400px;
    cursor: pointer;
}
</style>
