<template>
    <v-row v-if="order">
        <v-col cols="12" sm="6" class="text-left" v-if="$store.getters.vendor?.paymentDetails?.upiId">
            <send-payment-details btnStyle="btn" v-if="$store.getters.vendor?.paymentDetails?.upiId && order.pStatus != 'PAID'" :order="order"></send-payment-details>
            <send-payment-reminder btnStyle="btn" v-if="$store.getters.vendor?.paymentDetails?.upiId && order.pStatus != 'PAID'" :order="order"></send-payment-reminder>
        </v-col>
        <v-col cols="12" :sm="$store.getters.vendor?.paymentDetails?.upiId ? 6 : 12"><v-btn class="mx-2" v-if="enableActionDraft"
                @click="updateOrder(orderStatus.DRAFT_STATUS)">{{ orderStatus.DRAFT_STATUS }}</v-btn>
            <v-btn class="mx-2" v-if="enableActionPending"
                @click="checkStockAndUpdate(orderStatus.PENDING_STATUS)">SAVE</v-btn>
            <v-btn v-if="enableActionPaid" @click="updateOrder(paymentStatus.CREDIT)">Create Order</v-btn>
            <v-btn v-for="action in allowedActions" v-if="action !== 'CANCEL'" :key="action"
                @click="toggleCommentBox(action)" :style="theme" class="mr-1">{{ action }}</v-btn>
            <payment-details v-if="order.updatedInventory && (order.balance == undefined ? true : order.balance > 0)"
                :order="order" @updatedPaymentDetails="$emit('updatedPaymentDetails')"></payment-details>
            <back-button></back-button></v-col>
        <app-dialog :show="show" @close="show = !show" :title="selectedAction">
            <template v-if="order.stock == 'STOCK_MISMATCH'">
                <h4 :class="$vuetify.breakpoint.xsOnly ? 'card-title' : ''">You cannot accept this order as there is
                    insufficient quantity available for following items.<br>Please Modify quantity to accept Order</h4>
                <br>
                <v-simple-table v-if="$vuetify.breakpoint.smAndUp">
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Title</th>
                            <th>Requested</th>
                            <th>Available</th>
                            <th>New Quantity</th>
                        </tr>
                    </thead>
                    <tbody v-for="item in order.items" :key="item._id">
                        <tr v-if="item.availableStock >= 0">
                            <td>{{ item.code }}</td>
                            <td>{{ item.title }}</td>
                            <td class="text-right">{{ item.quantity }}</td>
                            <td class="text-right">{{ item.availableStock }}</td>
                            <td>
                                <v-text-field class="text-field mt-2 centered-input" v-model="item.newQuantity"
                                    flat></v-text-field>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <template v-for="item in order.items">
                    <v-card class="my-1" :key="item._id" v-if="$vuetify.breakpoint.xsOnly">
                        <v-flex>
                            <v-card-title>{{ item.title }}</v-card-title>
                            <v-card-subtitle>{{ item.code }}</v-card-subtitle>
                        </v-flex>
                        <v-layout wrap>
                            <v-flex xs4>
                                <v-card-subtitle style="color:red" class="card-text">Requested</v-card-subtitle>
                                <v-card-subtitle style="color:red"
                                    class="item-value justify-center">{{ item.quantity }}</v-card-subtitle>
                            </v-flex>
                            <v-flex xs4>
                                <v-card-subtitle style="color:green" class="card-text">Available</v-card-subtitle>
                                <v-card-subtitle style="color:green"
                                    class="item-value justify-center">{{ item.availableStock }}</v-card-subtitle>
                            </v-flex>
                            <v-flex xs4>
                                <v-text-field style="width:80%" class="centered-input" label="New Qty"
                                    v-model="item.newQuantity"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </template>
            </template>
            <v-textarea label="Enter your comments" v-model="comments"></v-textarea>
            <v-flex text-right v-if="order.stock == 'STOCK_MISMATCH'">
                <v-btn @click="updateOrder(selectedAction)" class="mx-2" :style="themeInverted">Modify and
                    Accept</v-btn>
                <v-btn @click="show = !show">Cancel</v-btn>
            </v-flex>
            <v-flex text-right v-else>
                <v-btn :style="themeInverted" @click="updateOrder(selectedAction)">Submit</v-btn>
            </v-flex>
        </app-dialog>
    </v-row>
</template>

<script>
import appConstants from '@/utils/appConstants'
import PaymentDetails from '@/components/PaymentDetails.vue';
import SendPaymentDetails from './SendPaymentDetails.vue';
import SendPaymentReminder from './SendPaymentReminder.vue';

export default {
    components: {
        PaymentDetails,
        SendPaymentDetails,
        SendPaymentReminder
    },
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            show: false,
            comments: "",
            actions: [],
            selectedAction: "",
            acceptOrder: true
        }
    },
    computed: {
        order: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit(this.appEvents.INPUT, newValue)
            }
        },
        allowedActions() {
            if (this.order.currentStatus && this.actions.length == 0 && this.order._id) {
                this.getItem(appConstants.ORDER_ALLOWED_ACTIONS.replace('$id', this.order._id)).then(response => {
                    this.actions = response
                })
            }
            if (this.actions[0] != null)
                return this.actions
        },
        enableActionDraft() {
            return this.order.pStatus == this.orderStatus.DRAFT_STATUS
        },
        enableActionPaid() {
            return this.order.pStatus == this.orderStatus.DRAFT_STATUS
            /* switch(this.order.pStatus){
                case this.paymentStatus.COD:
                case this.paymentStatus.CREDIT:return false;break;
                default: return true; break 
            } */
        },
        enableActionPending() {
            return this.order.currentStatus == this.orderStatus.PENDING_STATUS
        }
    },
    methods: {
        resetComponent() {
            this.show = !this.show
            this.comments = ""
            this.actions = []
        },
        toggleCommentBox(title) {
            this.order.items.forEach(item => {
                if (item.availableStock >= 0)
                    item.newQuantity = item.availableStock
            })
            this.selectedAction = title
            this.show = !this.show
        },
        updateOrder(action) {
            this.order.items.forEach(rec => {
                if (rec.availableStock < rec.newQuantity) {
                    this.acceptOrder = false
                    alert("New quantity cannot be greater than Available quantity")
                }
            })
            if (this.acceptOrder) {
                if (this.paymentStatus.CREDIT == action || this.paymentStatus.COD == this.order.pStatus)
                    this.order.pStatus = this.paymentStatus.CREDIT
                this.order.action = action
                this.order.comment = this.comments
                this.checkStockAndUpdate(action)
            }
            this.acceptOrder = true
        },
        async checkStockAndUpdate(action) {
            if (this.order.stock == 'STOCK_MISMATCH') {
                this.setQuantity()
                this.order.total = this.calculateTotal(this.order)
            }
            await this.putItem("/api/orders/" + this.order._id, this.order)
            this.$emit("clikedUpdateAction", action)
            this.back()
        },
        setQuantity() {
            this.order.items.forEach(item => {
                if (item.availableStock >= 0)
                    item.quantity = item.newQuantity
            });
            this.order.stock = ''
        },
    },
}
</script>

<style scoped>
.text-field {
    width: 50px;
    padding-top: 20%;
}

h4 {
    color: red
}

.item-code {
    font-size: 11px !important;
    padding-bottom: 0%;
}

.card-text {
    font-size: 13px;
    padding: 0;
    padding-left: 20%;
}

.centered-input>>>input {
    padding-top: 15px;
    text-align: center;
    font-size: 1.2rem;
}

.item-value {
    font-size: 1.1rem;
    padding-bottom: 0;
    padding-left: 40%;
}
</style>
