<template>
    <v-flex>
        <v-app-bar :style="theme" dark :clipped-left="isLaptopAndUp" app dense>
            <v-app-bar-nav-icon :style="theme" @click="drawer = !drawer" v-if="isLoggedIn"></v-app-bar-nav-icon>
            <v-toolbar-title class="title-padding" v-if="$vuetify.breakpoint.mdAndUp">
                <router-link class="d-flex align-center" to="/app">
                    <img v-if="$store.getters.vendor.logo" class="logo" width="10%"
                        :src="host + $store.getters.vendor.logo.path">
                    <v-icon v-else x-large class="mr-2" :style="theme">mdi-handshake</v-icon>
                    <label class="title" :style="theme">{{ storeName }}</label>
                </router-link>
            </v-toolbar-title>
            <v-toolbar-title class="title-padding" v-if="$vuetify.breakpoint.smAndDown">
                <router-link class="d-flex align-center" to="/store">
                    <v-img class="mr-2" v-if="$store.getters.vendor.logo" :src="host + $store.getters.vendor.logo.path"
                        height="50px" width="60px"></v-img>
                    <v-icon v-else large class="mr-2" :style="theme">mdi-handshake</v-icon>
                    <label class="title-font wrap-word" :style="theme">{{ storeName }}</label>
                </router-link>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div align="right" v-if="$vuetify.breakpoint.smAndUp">
                <v-layout align-content-end style="baground-color:red">
                    <v-btn icon @click="clearNotificationAndNavigate()">
                        <v-badge :value="orderCounter" color="pink" dot offset-y="5" offset-x="10">
                            <v-icon medium :style="theme">mdi-bell</v-icon>
                        </v-badge>
                    </v-btn>
                    <label v-if="isLoggedIn" class="mt-3 mr-2">Welcome {{ getUserProfile().displayName }}</label>
                    <app-titlebar-menu></app-titlebar-menu>
                </v-layout>
            </div>
            <div align="right" v-if="$vuetify.breakpoint.xsOnly">
                <v-btn icon @click="clearNotificationAndNavigate()">
                    <v-badge :value="orderCounter" color="pink" dot offset-y="5" offset-x="10">
                        <v-icon medium :style="theme">mdi-bell</v-icon>
                    </v-badge>
                </v-btn>
                <app-titlebar-menu></app-titlebar-menu>
            </div>
        </v-app-bar>
        <app-sidebar v-model="drawer" />
    </v-flex>
</template>

<script>
import mixins from "@/mixins/commonFunctions.js";
import AppSidebar from "@/components/AppSidebar.vue"
import appConstants from '@/utils/appConstants'
import RowItem from './RowItem.vue';
import AppTitlebarMenu from './AppTitlebarMenu.vue';
/**
 * This is default Application titlebar. `<app-titlebar>`
 */
export default {
    mixins: [mixins],
    components: {
        AppSidebar,
        RowItem,
        AppTitlebarMenu
    },
    data() {
        return {
            on: false,
            drawer: false,
            plan: {
                creditBalance: 0,
                name: '',
                expiryDate: ''
            }
        };
    },
    mounted() {
        this.initComponent()
        this.drawer = this.isLaptopAndUp
    },
    methods: {
        async initComponent() {
            var items = await this.getItem(appConstants.BILLING_ACCOUNT_API + "/vendor/" + this.$store.getters.vendor._id)
            this.plan.name = items[0]?.plan?.displayName
            this.plan.creditBalance = items[0]?.creditBalance
            this.plan.expiryDate = items[0]?.expiryDate
        },
        clearNotificationAndNavigate() {
            this.$store.dispatch("actionUpdateOrderCount");
            this.$router.push('/app/orders')
        },
    },
};
</script>

<style lang="css" scoped>
.title-font {
    font-size: 14px;
}

.title-padding {
    padding-left: 0% !important;
}

.wrap-word {
    white-space: normal;
}
</style>
