<template>
<v-chip class="font" label block :small="$vuetify.breakpoint.mdAndUp" :x-small="$vuetify.breakpoint.smAndDown" dark :color="statusColor" v-if="status">{{status}}</v-chip>
</template>

<script>
export default {
    props: {
        status: {
            type: String,
            default: () => 'CREATED',
        },
    },
    computed: {
        statusColor: {
             get(){
                 return this.checkStatus()
             }
        }
    },
    methods: {
        checkStatus() {
            switch (this.status) {
                case 'CREATED':
                    return 'orange'
                case 'ACTIVE':
                    return 'success'
                case 'EXPIRED':
                    return 'success'
                case 'SUSPENDED':
                    return 'grey'
                case 'PAUSED':
                    return 'brown'
                case 'CANCELLED':
                    return 'red'
                default:
                    return 'orange'
            }
        }
    },
}
</script>

<style scoped>
.font{
    font-size: 0.7rem !important;
}
@media only screen and (max-width: 400px) {
    .font{
        font-size: 0.6rem !important;
    }
}
</style>
